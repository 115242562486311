@import '@dls/web/assets/fonts.css';

body {
  margin: 0;
}

img {
  display: block;
}

.header {
  position: relative;
  z-index: 1;
}

.mt-8 {
  margin-top: 8px !important;
}
