.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
}

.container {
  overflow-y: scroll;
  position: relative;
}

.loading {
  overflow: hidden;
}

.encrypt-wrapper {
  height: calc(100% - 189px);
}

@media (max-width: 440px) {
  .footer {
    display: none;
  }
}
